<template>
    <section class="gardenManagementPage">
        <g-breadcrumb bread="会员管理" />
   
        <TableContainer title="">

            <el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
                :cell-style="{ textAlign: 'center' }">
                <el-table-column prop="userId" label="编号" min-width="60" />
                <el-table-column prop="userName" label="会员名称" min-width="60" />
                <el-table-column prop="regDate" label="注册日期" min-width="60" />
                <el-table-column prop="isCertification" label="实名认证" min-width="60" />
                <el-table-column prop="parentId" label="推荐ID" min-width="100" />
                <el-table-column width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" class="textOrange" @click="dissolutionFormClick(scope.row)" v-show="scope.row.parentId!==0">解绑</el-button>
                        <!-- <el-button type="text" class="textOrange" @click="delteFormClick(scope.row)">删除</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
                :size.sync="searchForm.size" @pagination="bxUser" />
        </TableContainer>






    </section>
</template>

<script>
import {
    bxUser,
    bxUserUnbinding
} from '@api/bx/api_contentManagement';
export default {
    data() {
        return {
            themeDialog: {
                title: '信息框',
                visible: false
            },
            searchForm: {
                page: 1,
                size: 10
            },
            total: 0,
            tableData: [],
            typeList: [],
            dicCode: null,
            addCode: null,
            addItem: {},
            dicName: '',
            isEditor: false,
            editorRow: {},
            parmas: {
                zhName: '',//中文名称
                enName: '',//英文名称
                materialHistory: '',//原料含量
                waterCosmetics: '',//复配百分比
                residencyCosmetics: '',//实际成分含量
                saltRule: '',//使用目的
                no: '',//原料序号
                saltRule: '',//化妆品安全技术规范位置
            }
        }
    },
    created() {
        // this.bxUser()

    },
    methods: {
        tabHeader() {
            return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
        },

        // 会员管理列表
        bxUser() {
            bxUser(this.searchForm).then(res => {
                this.tableData = res.records
				this.total = res.total

            })
        },
      
        //解绑
        dissolutionFormClick(row){
            this.$confirm('是否脱离关系?', '提示', {
				confirmButtonText: '脱离关系',
				cancelButtonText: '关闭',
				type: 'warning'
			}).then(() => {
				bxUserUnbinding({ userId: row.userId }).then(res => {
                    this.$message.success('解绑成功')
					this.bxUser()
				})
			})
        },
        	//表格删除
		delteFormClick(row) {
			this.$confirm('永久删除' + '这条数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				// cfdelCosmeticsMaterial({ batchMonomer: row.batchMonomer }).then(res => {
				// 	this.cfqueryCosmeticsMaterial()
				// })
			})

		}


    }
}
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.turnBule {
    color: #fff;
    background-color: #58e3e8;
    border-color: rgba(56, 185, 190, 0.12);
    margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
    background: rgba(56, 185, 190, 0.5);
    border-color: rgba(56, 185, 190, 0.5);
    color: #fff;
}

.el-button--text.reviewed {
    color: #38b9be;
}

.reviewed {
    color: #38b9be;
}

.el-tree-node__content {
    z-index: 999;
    padding-left: 40px;

}

.directory-in {
    width: 32%;
    line-height: 60px;
    background: #4095E5;
}
</style>

